<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-white">
        <div class="modal-header bg-light-grey text-bold text-dark-blue p-3">
          <span>{{ title }}</span>
          <modal-close-button @confirmed="closeModal" />
        </div>
        <div class="modal-body">
          <div class="row px-3 pb-3">
            <div class="col-12">{{ text }}</div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-sm btn-grey mr-3"
                data-dismiss="modal"
                @click="closeModal"
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                class="btn btn-sm"
                :class="okColor"
                @click="ok"
              >
                <span>{{ okText }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
	
	<script>
import { modal } from "../../mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "MessageModal",
  components: {
    ModalCloseButton,
  },
  props: {
    id: {
      type: String,
      default: "message-modal",
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    okText: {
      type: String,
      default: null,
    },
    okColor: {
      type: String,
      default: "btn-dark-blue",
    },
  },
  data() {
    return {};
  },
  mixins: [modal],
  computed: {},
  mounted() {},
  methods: {
    ok() {
      this.$emit("ok");
      this.closeModal();
    },
  },
};
</script>
	