<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-white">
        <div class="modal-header bg-light-grey text-bold text-dark-blue p-3">
          <span>Add company</span>
          <modal-close-button @confirmed="closeModal" />
        </div>
        <div class="modal-body">
          <div class="container px-3 pb-3">
            <!-- <div class="row mb-2 ml-0">
              <div class="col-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="statusCheckbox"
                    :checked="company.status === 'Active'"
                    @change="updateCompanyStatus"
                  />
                  <label class="form-check-label" for="statusCheckbox">
                    {{ company.status === "Active" ? "Active" : "Inactive" }}
                  </label>
                </div>
              </div>
            </div> -->

            <div class="row mb-2 ml-0">
              <div class="col-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Company name"
                    :disabled="editCompany"
                    v-model="company.name"
                  />
                  <label>Company name*</label>
                </div>
              </div>
            </div>

            <div class="row mb-2 ml-0">
              <div class="col-6">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Company phone number"
                    v-model="company.phone"
                  />
                  <label>Company phone number</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Company email address"
                    :disabled="editCompany"
                    v-model="company.email"
                  />
                  <label>Company email address*</label>
                </div>
              </div>
            </div>

            <div class="row ml-0">
              <div class="col-6">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Company address"
                    v-model="company.address"
                  />
                  <label>Company address</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Company website"
                    v-model="company.website"
                  />
                  <label>Company website</label>
                </div>
              </div>
              <div class="col-6 fs-10">*Mandatory</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-sm btn-grey mr-3"
                data-dismiss="modal"
                @click="closeModal"
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                :disabled="!formIsValid"
                @click="saveCompany"
              >
                <span v-if="!editCompany">Send registration link</span>
                <span v-else>Edit company</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { modal } from "../../mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

import { uuidv4 } from "@/utils/Uuid";
//import CompaniesVue from '../../pages/Companies.vue';

export default {
  name: "CompanyModal",
  components: {
    ModalCloseButton,
  },
  props: {
    id: {
      type: String,
      default: "company-modal",
    },
    editCompany: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      company: {
        name: null,
        address: null,
        phone: null,
        email: null,
        website: null,
        status: "Active",
      },
      defaultCompany: {
        name: null,
        address: null,
        phone: null,
        email: null,
        website: null,
        status: "Active",
      },
    };
  },
  mixins: [modal],
  computed: {
    formIsValid() {
      if (this.company.name && this.company.email) return true;
      return false;
    },
  },
  mounted() {
    this.$bus.$on("open-modal", (id) => {
      if (id === "company-modal") {
        this.$nextTick(() => {
          if (this.editCompany) {
            this.company = JSON.parse(JSON.stringify(this.editCompany));
          } else {
            this.company = JSON.parse(JSON.stringify(this.defaultCompany));
          }
        });
      }
    });
    // this.$bus.$on("open-modal", (val) => {
    //   if (val.company) {
    //     this.company = val.company;
    //   }

    //   if (id === "company-modal") {
    //     this.$nextTick(() => {
    //       //this.getDubletten();
    //     });
    //   }
    //});
  },
  methods: {
    saveCompany() {
      this.$emit("addCompany", this.company);
      this.closeModal();
    },
    updateCompanyStatus() {
      if (this.company.status === "Active") {
        this.company.status = "Inactive";
      } else {
        this.company.status = "Active";
      }
    },
  },
};
</script>
  