<template>
  <div class="container-component">
    <div class="row mb-4">
      <div class="col">
        <div
            class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h5 class="mb-0 font-size-18">Companies</h5>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="mb-5">
                  <h6 class="card-title">
                    Company List
                    <span
                        class="text-muted fw-normal ms-2"
                        v-if="listData?.length > 0"
                    >({{ listData?.length }})</span
                    >
                  </h6>
                </div>

                <div class="col-12 d-flex mb-4">
                  <button
                      class="btn btn-sm btn-success"
                      @click="openCompanyModal"
                  >
                    <font-awesome-icon icon="fa-solid fa-plus"/>
                    <span class="ml-2">New company</span>
                  </button>
                  <!-- <button
                    class="btn btn-sm btn-grey ml-auto"
                    :disabled="selectedObjects.length === 0"
                    @click="deactivateCompany"
                  >
                    <font-awesome-icon icon="fa-solid fa-ban" />
                    <span class="ml-2">Deactivate selected companies</span>
                  </button> -->
                  <button
                      class="btn btn-sm btn-danger-light ml-auto"
                      :disabled="selectedObjects.length === 0"
                      @click="oeffneMessageModal"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash"/>
                    <span class="ml-2">Delete selected companies</span>
                  </button>
                </div>

                <div class="col-12">
                  <div class="table-responsive">
                    <div class="table align-middle">
                      <b-table
                          class="companiesTable"
                          ref="selectableTable"
                          :items="listData"
                          :fields="fields"
                          responsive="sm"
                          selectable
                          select-mode="range"
                          tbody-tr-class="item"
                          @row-dblclicked="oeffneCompany"
                      >
                        <template v-slot:head(selected)="header">
                          <b-form-checkbox
                              ref="headerCheckbox"
                              v-model="header.selected"
                              @input="selectAllRows(header)"
                          ></b-form-checkbox>
                        </template>

                        <template v-slot:cell(selected)="row">
                          <b-form-checkbox
                              v-model="row.item.selected"
                              @input="onRowSelected(row)"
                          ></b-form-checkbox>
                        </template>

                        <template slot="cell(website)" slot-scope="{ value }">
                          <a class="clickable" :href="value">{{ value }}</a>
                        </template>

                        <template slot="cell(status)" slot-scope="{ value }">
                          <span
                              v-if="value != 'Active'"
                              class="text-danger-light"
                          >{{ value }}</span
                          >
                          <span v-else class="text-success">{{ value }}</span>
                        </template>

                        <template v-slot:cell(edit)="row">
                          <div
                              class="clickable"
                              @click="setEditCompany(row.item)"
                          >
                            <font-awesome-icon icon="fa-solid fa-pencil"/>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <company-modal
        id="company-modal"
        @addCompany="addCompany"
        :editCompany="editCompany"
    />
    <message-modal
        id="message-modal"
        :title="selectedIds.length > 1 ? 'Delete companies' : 'Delete company'"
        :text="
        selectedIds.length > 1
          ? 'Are you sure you want to delete the selected companies?'
          : 'Are you sure you want to delete the selected company?'
      "
        :okText="selectedIds.length > 1 ? 'Delete companies' : 'Delete company'"
        :okColor="'btn-danger-light'"
        @ok="deleteCompany"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import {uuidv4} from "@/utils/Uuid";

import Table from "@/mixins/Table";
//import Dashboard from "@/mixins/Dashboard";

import CompanyModal from "@/components/Modals/CompanyModal";
import MessageModal from "@/components/Modals/MessageModal";

export default {
  name: "Companies",
  components: {
    CompanyModal,
    MessageModal,
  },
  mixins: [Table /*Dashboard*/],
  props: {},
  data() {
    return {
      editCompany: null,
      listData: [],
      fields: [
        {key: "selected", label: ""},
        {key: "name", label: "Name"},
        {key: "address", label: "Address"},
        {key: "phone", label: "Phone"},
        {key: "email", label: "Email"},
        {key: "website", label: "Website"},
        {key: "checksum", label: "Generated QR-Codes"},
        //{ key: "status", label: "Status" },
        {key: "edit", label: "Edit"},
      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
    },
    queryParams: function () {
      var params = {};

      // if (this.filters.size && this.filters.size != "") {
      //   params.size = this.filters.size;
      // }
      return params;
    },
  },
  watch: {},
  created() {
    //this.initFilter("companies-liste", "company/", true);
  },
  mounted() {
    this.getCompanies();
    //this.$bus.$emit("searchWithFilter");
  },

  methods: {
    getCompanies() {
      if (this.user && this.user?.role?.id === "1889B2E8093") {
        //Administrator
        Api.get("company/").then((response) => {
          this.listData = response?.data.content;
        });
      } else if (this.user && this.user?.role?.id === "18EEC46A8DB") {
        Api.get("company/" + "?countryID=" + this.user.id).then((response) => {
          this.listData = response?.data.content;
        });
      }
    },
    openCompanyModal() {
      this.editCompany = null;
      this.$nextTick(() => {
        this.$bus.$emit("open-modal", "company-modal");
      });
    },
    setEditCompany(company) {
      this.editCompany = company;
      this.$bus.$emit("open-modal", "company-modal");
    },
    addCompany(company) {
      if (company) {
        if (company.id) {
          Api.put("company/" + company.id, company)
              .then((response) => {
                this.$notify({
                  type: "success",
                  title: "Action Successful",
                  text: "Company successfully updated.",
                  duration: 8000,
                });
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                //this.resetLoading(this)
                this.getCompanies();

                this.$nextTick(() => {
                  this.$refs.selectableTable.refresh();
                });
              });
        } else {
          company.countryadmin = this.user.id
          Api.post("company/", company).then((response) => {
            if (response.status === 200) {
              this.$notify({
                type: "success",
                title: "Action Successful",
                text: "Company successfully created and registration link sent.",
                duration: 8000,
              });
            } else {
              this.$notify({
                type: "error",
                title: "Action failed",
                text: response.data,
                duration: 10000,
              });
            }
          })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                //this.resetLoading(this)
                this.getCompanies();

                this.$nextTick(() => {
                  this.$refs.selectableTable.refresh();
                });
              });
        }
      }
    },
    oeffneCompany(company) {
      this.$router.push({
        name: "company",
        params: {id: company.id},
      });
    },
    // deactivateCompany() {
    //   this.selectedObjects.forEach((company) => {
    //     company.status = { name: "Inactive" };
    //   });
    // },
    oeffneMessageModal() {
      this.$bus.$emit("open-modal", "message-modal");
    },
    deleteCompany() {
      Api.delete("company/", {data: this.selectedIds})
          .then((response) => {
            this.$notify({
              type: "success",
              title: "Action Successful",
              text: "Company successfully deleted.",
              duration: 8000,
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            //this.resetLoading(this)
            this.getCompanies();

            this.$nextTick(() => {
              this.$refs.selectableTable.refresh();
            });
          });
    },
  },
};
</script>

<style lang="scss">
</style>
			  