export default {
	components: {
	},
	data() {
		return {
			selectedIds: [],
			selectedObjects: [],
			selectAll: null,
		};
	},
	watch: {

	},
	mounted() {
	},
	computed: {
	},
	methods: {
		selectAllRows(row) {
			if (row.selected) {
				this.$refs.selectableTable.items.forEach((item) => {
					this.$set(item, "selected", true);
				});
				this.selectedObjects = this.$refs.selectableTable.items;
				this.selectedIds = this.$refs.selectableTable.items.map(
					(item) => item.id
				);

				this.anzahlDaten = this.selectedIds.length;
			} else {
				this.$refs.selectableTable.items.forEach((a) => {
					this.$set(a, "selected", false);
				});
				this.selectedIds = [];
				this.selectedObjects = [];
			}

			this.selectAll = Date.now();
		},

		onRowSelected() {
			if (this.selectAll) {
				let timeDiff = Date.now() - this.selectAll;
				if (timeDiff < 100) return;
			}

			this.selectedObjects = this.$refs.selectableTable.items.filter(
				(item) => item.selected
			);

			this.selectedIds = this.selectedObjects.map((item) => item.id);
		},

		clearSelectedRows() {
			if (this.$refs.headerCheckbox)
				this.$refs.headerCheckbox.localChecked = false;
		},
	},
};
